@import url('https://fonts.googleapis.com/css2?family=Permanent+Marker&display=swap');

.app {
	background-color: #e0851c;
	height: -webkit-min-content;
	height: -moz-min-content;
	height: min-content;
	border-radius: 15px;
	padding: 20px;
	box-shadow: 10px 10px 42px 0 rgba(0,0,0,.75);
	display: flex;
	justify-content: space-evenly;
	flex-direction: column;
}

.app-img {
  display: block;
  width: 800px;
  margin-bottom: 50px;
  margin: auto;
}

.app, .app-img {
	width: 800px;
	margin-bottom: 50px;
}

.visits {
  text-align: center;
  color: #e0851c;
}

.score-section {
  display: flex;
  font-size: 24px;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}

.credits-section {
  display: flex;
  flex-direction: column;
  font-size: 22px;
  align-items: flex-start;
  justify-content: start;
  margin-bottom: 20px;
}

.credits-section p {
  margin: 10px;
}

.credits-section ul {
  margin: 10px;
}

/* QUESTION/TIMER/LEFT SECTION */
.question-section {
  width: 100%;
  position: relative;
}

.question-count {
  margin-bottom: 20px;
  font-size: 20px;
  font-family: 'Permanent Marker', cursive;
}

.question-count span {
  font-size: 32px;
  font-family: 'Permanent Marker', cursive;
}

.about-img {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  font-style: italic;
}

.question-img {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  font-style: italic;
}

.question-img img {
  width: 20%;
  border-radius: 15px;
  border: 8px solid #06D6A0;
}

.show-answer-img img {
  width: 80%;
  border-radius: 15px;
  border: 8px solid #06D6A0;
}

.question-text {
  margin-bottom: 24px;
}

.timer-text {
  background: rgb(230, 153, 12);
  padding: 15px;
  margin-top: 20px;
  margin-right: 20px;
  border: 5px solid rgb(255, 189, 67);
  border-radius: 15px;
  text-align: center;
}

/* ANSWERS/RIGHT SECTION */
.answer-section {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

button {
  width: 100%;
  font-size: 32px;
  color: #ffffff;
  background-color: #EF476F;
  border-radius: 15px;
  display: flex;
  padding: 10px;
  justify-content: center;
  align-items: center;
  border: 8px solid #FFD166;
  cursor: pointer;
  font-family: 'Permanent Marker', cursive;
  margin: 2px;
}

.answer-choice {
  height: 200px;
  background-size: 180px;
  background-position: center;
}

img.correct {
  border-color: rgb(6, 255, 110);
}

img.incorrect {
  border-color: #CE123E;
}

.show-answer {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.show-answer-imgs {
  display: flex;
  flex-direction: row;
}

.show-answer-explain {
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
}

img.harder-img {
  width: 200px;
  margin-left: 20px;
}

img.tick {
  width: 200px;
  border: none;
  margin: 30px;
}

img.cross {
  width: 100px;
  border: none;
  margin: 30px;
}

.trophy-section {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.trophy-section img {
  width: 200px;
  margin-bottom: 20px;
}

button:hover {
  background-color: #CE123E;
  border-color: #FFBC1F;
}

button:focus {
  outline: none;
}

button svg {
  margin-right: 5px;
}

.start-btn {
  background-color: #06D6A0;
}

.start-btn:hover {
  background-color: rgb(6, 255, 110);
}

.team-pics {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}

.team-pic {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.team-pic img {
  width: 100px;
  border-radius: 15px;
  border: 8px solid #06D6A0;
}

.nei-img {
  width: 800px;
  display: flex;
  justify-content: start;
  align-items: baseline;
  margin-top: 20px;
  margin-bottom: 20px;
}

.nei-img img {
  width: 30%;
}

.nei-img span {
  margin-left: 10px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-weight: bolder;
  color: black;
}
